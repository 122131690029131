import $ from 'jquery';

var prev = 0;
var onTop = 0;
var $window = $(window);
var nav = $('.scrollhide-nav');

$window.on('scroll', function(){
  var scrollTop = $window.scrollTop();
  nav.toggleClass('hidden', scrollTop > prev);
  prev = scrollTop;
  var scrolling = $window.scrollTop();
  nav.toggleClass('filled', scrolling > onTop);
  onTop = 0;
});
